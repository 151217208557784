import React from "react";
import Img from "gatsby-image";
import "./index.css";
import "../style/font-style.css";
import Box from "@material-ui/core/Box";
import AOS from "aos";
import "aos/dist/aos.css";
import Head from "../components/head";
import Header from "../components/Header_normal";
import Footer from "../components/footer";
import { useStaticQuery, graphql, Link } from "gatsby";
import WrapTypo from "../components/WrapTypo";
import Grid from "@material-ui/core/Grid";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

{
  /* <h1
  style={{
    background:
      "linear-gradient( to right,  rgba(69,179,224,1) 25%, rgba(0,51,153,1) 75% )",
    WebkitBackgroundClip: "text",
    webkitTextFillColor: "transparent",
  }}
>
  fasfasd
        </h1> */
}
export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    });
  }
  const data = useStaticQuery(graphql`
    query {
      book: file(relativePath: { eq: "takyoten_life.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fv_all: file(relativePath: { eq: "fv_all.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product: file(relativePath: { eq: "product@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productDetail: file(relativePath: { eq: "productDetail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      anj: file(relativePath: { eq: "anjusImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pc_anj: file(relativePath: { eq: "pc_anju.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sp_anj: file(relativePath: { eq: "sp_anju.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Head />
      <Box width="100%" bgcolor="#E5E5E5">
        <Header />

        <Box width="90%" maxWidth="1200px" m={"auto"} mt="50px">
          <Box>
            <GridResponsiveDirection>
              <Grid item xs={12} sm={6}>
                <WrapTypo
                  variant="h1"
                  fs="63px"
                  mt="32px"
                  xsfs="30px"
                  mdfs="40px"
                  color="#474747"
                >
                  石山 アンジュ
                </WrapTypo>
                <WrapTypo fs="16px" lh="24px" ls="10%">
                  Anju　Ishiyama
                </WrapTypo>
                <WrapTypo mt="32px" fs="18px" lh="200%" Yumincho xsfs="14px">
                  一般社団法人Public Meets Innovation代表
                  <br />
                  デジタル庁シェアリングエコノミー伝道師
                  <br />
                  一般社団法人シェアリングエコノミー協会 代表理事
                  <br />
                  株式会社パブリックボーダーズ 代表取締役
                </WrapTypo>
                <WrapTypo
                  Notosans
                  fs="14px"
                  lh="180%"
                  mt="32px"
                  xsfs="14px"
                  style={{ textAlign: "justify" }}
                  mb="64px"
                >
                  1989年生まれ。「シェア(共有)」の概念に親しみながら育つ。シェアリングエコノミーを通じた新しいライフスタイルを提案する活動を行うほか、政府と民間のパイプ役として規制緩和や政策推進にも従事。
                  2018年10月ミレニアル世代のシンクタンク一般社団法人Public Meets
                  Innovationを設立。
                  新しい家族の形「拡張家族」を掲げるコミュニティ拡張家族Cift
                  家族代表。世界経済フォーラム Global Future Council
                  Japanメンバー。USEN-NEXT HOLDINGS
                  社外取締役。ほかに「羽鳥慎一モーニングショー」木曜レギュラー、「真相報道バンキシャ！」「アサデス。」「ドデスカ！」にコメンテーターとして定期出演。2012年国際基督教大学(ICU)卒。新卒で(株)リクルート入社、その後(株)クラウドワークス経営企画室を経て現職。デジタル庁シェアリングエコノミー伝道師。大分と東京の二拠点生活。著書に「シェアライフ-新しい社会の新しい生き方-」、新著に「多拠点ライフ-分散する生き方-」Forbes JAPAN「日本のルールメイカー30人」に選出。米づくり3年目、特技は大人数料理を作ること。
                </WrapTypo>
                <WrapTypo
                  mt="32px"
                  fs="18px"
                  lh="200%"
                  Yumincho
                  xsfs="14px"
                  mt="0"
                >
                  著書 「分散する生き方　多拠点ライフ」　
                </WrapTypo>
                <WrapTypo
                  mt="32px"
                  fs="16px"
                  lh="200%"
                  Yumincho
                  xsfs="12px"
                  mt={"0"}
                >
                  「いくつもの選択肢を持つこと」が豊かさのスタンダードになる時代
                </WrapTypo>

                <WrapTypo
                  Notosans
                  fs="14px"
                  lh="180%"
                  mt="8px"
                  xsfs="12px"
                  style={{ textAlign: "justify" }}
                  mb="16px"
                >
                  別荘、ワーケーション、バンライフ。
                  「もう一つの家がある」生き方はもはや一部の人のものではなく、
                  急速なリモートワークの普及、デジタル化によって、誰でも今すぐ始められる時代になった。
                  コロナウィルスの拡大を機に働き方だけでなく暮らし方、
                  ライフスタイルそのものを見直す人が急増する中、
                  「分散する生き方」は
                  これからの社会の豊かさのスタンダートだ。
                  多拠点生活実践者であり日本のシェアリングエコノミーの第一人者である
                  著者が、
                  多拠点ライフで変わる、新しい社会と生き方、今から始められる実践方法を提示する。
                  【本書の構成】 序 章　私の多拠点ライフ
                  第1章　なぜ、今多拠点ライフなのか？
                  第2章　多拠点ライフで新しい幸せを手に入れる
                  第3章　多拠点ライフで見つけたそれぞれの幸せの形
                  第4章　多拠点ライフの心得。自分にも地球にもサステナブルな生き方をしよう
                  第5章　実践！ 今すぐ多拠点ライフをはじめる入門ガイド
                </WrapTypo>

                <WrapTypo
                  Notosans
                  fs="14px"
                  lh="180%"
                  mt="8px"
                  xsfs="14px"
                  style={{
                    textAlign: "right",
                  }}
                  mb="64px"
                  color="rgba(128,161,221,1)"
                >
                  <a
                    href="https://amzn.asia/d/e8d4bco"
                    alt="シェアライフ"
                    style={{
                      borderBottom: "solid 1px #rgba(128,161,221,1)",
                      color: "rgba(128,161,221,1)",
                    }}
                  >
                    Amazonで購入する→
                  </a>
                </WrapTypo>
                <WrapTypo
                  mt="32px"
                  fs="18px"
                  lh="200%"
                  Yumincho
                  xsfs="14px"
                  mt="0"
                >
                  著書 「シェアライフ 新しい社会の新しい生き方」　
                </WrapTypo>
                <WrapTypo
                  mt="32px"
                  fs="16px"
                  lh="200%"
                  Yumincho
                  xsfs="12px"
                  mt={"0"}
                >
                  人とのつながり、共有することで生まれる「新しい社会の新しい生き方」
                </WrapTypo>

                <WrapTypo
                  Notosans
                  fs="14px"
                  lh="180%"
                  mt="8px"
                  xsfs="12px"
                  style={{ textAlign: "justify" }}
                  mb="16px"
                >
                  ◎シェアリングエコノミー(共感経済・共有経済)とは?
                  ◎働き方・子育て・教育・住まいetc…人生100年時代の「幸せ」な生き方とは?
                  ◎地方創生・オリンピック・防災etc…新しい社会を支えるしくみとは?
                  近年、見聞きすることが多くなってきた「シェア」というキーワード。
                  たくさんのモノであふれる社会や生活の中から、
                  「必要なだけあれば十分だ」
                  「家も、仕事も、子育ても、誰かとシェア(共有)すればいい」
                  という価値観が生まれ、支持されるようになってきた。
                  あらゆるモノ・コトをシェアしながら生きていく
                  「シェアライフ」的生き方へのパラダイムシフトが
                  既に始まっているのだ。
                  さらに、シェアはライフスタイルだけでなく、
                  社会そのものを根本的に変革する可能性を秘めている。
                  この「シェア」こそ、これからの時代を幸せに生きていくために、
                  誰にとっても欠かせないキーワードになっていくはずだ。
                  シェアリングエコノミーの専門家による、
                  今すぐ始められるシェアライフ実践入門書!
                </WrapTypo>

                <WrapTypo
                  Notosans
                  fs="14px"
                  lh="180%"
                  mt="8px"
                  xsfs="14px"
                  style={{
                    textAlign: "right",
                  }}
                  mb="64px"
                  color="rgba(128,161,221,1)"
                >
                  <a
                    href="https://www.amazon.co.jp/dp/B07P1QHXQY/ref=cm_sw_em_r_mt_dp_N8NT7KG0FCM2FKVN4KSB"
                    alt="シェアライフ"
                    style={{
                      borderBottom: "solid 1px #rgba(128,161,221,1)",
                      color: "rgba(128,161,221,1)",
                    }}
                  >
                    Amazonで購入する→
                  </a>
                </WrapTypo>
                <WrapTypo
                  Notosans
                  fs="12px"
                  lh="180%"
                  mt="32px"
                  xsfs="12px"
                  style={{ textAlign: "justify" }}
                  mb="64px"
                >
                  公職： <br />
                  厚生労働省
                  「シェアリングエコノミーが雇用・労働に与える影響に関する研究会」委員
                  <br />
                  厚生労働省 「仲介事業業界として守るべきルール検討会」委員
                  <br />
                  経済産業省
                  「シェアリングエコノミーにおける経済活動の統計調査研究会」委員
                  <br />
                  総務省 「情報通信審議会 郵政政策部会 郵便局活性化委員会」委員
                  <br />
                  国土交通省
                  「新しい時代のインフラ・交通政策を考える懇談会」委員
                  <br />
                  国土交通省 「関係人口・ライフスタイルに関する懇談会」委員
                  <br />
                  スポーツ庁
                  「スポーツスキルとスポーツ施設のシェアリングエコノミー導入促進事業検討会」委員
                  <br />
                  経済産業省「「Connected Industries
                  推進のための協調領域データ共有・AI
                  システム開発促進事業／Society 5.0
                  の実現に向けたアーキテクチャに関する検討事業
                  /モビリティサービス分野アーキテクチャ検討委員会」委員 <br />
                  総務省 地域情報化アドバイザー 幹事 内閣官房
                  <br />
                  内閣官房シェアリングエコノミー伝道師 福岡市
                  <br />
                  スマートイースト研究会 委員 神戸市 　 スマートシティ研究会
                  委員 <br />
                  首相官邸「全世代型社会保障会議（2019年11月7日）」
                  <br />
                  <br /> ほか： <br /> 世界経済フォーラム Global Future Council
                  Japan メンバー
                  <br />
                  日経スマートシティ・インステイチュート アドバイザー News Picks
                  プロピッカー
                </WrapTypo>
                <WrapTypo
                  variant="h1"
                  fs="48px"
                  mt="32px"
                  xsfs="30px"
                  mdfs="40px"
                  color="#474747"
                >
                  Anju Ishiyama
                </WrapTypo>
                <WrapTypo
                  Notosans
                  fs="16px"
                  mt="32px"
                  xsfs="14px"
                  style={{ textAlign: "justify" }}
                  mb="24px"
                >
                  Founder of Japan Association of Public Meets Innovation
                  Secretariat of the Sharing Economy Association Japan Sharing
                  Economy Evangelist, Cabinet Secretariat, Government of Japan
                </WrapTypo>
                <WrapTypo
                  fs="14px"
                  lh="150%"
                  xsfs="12px"
                  style={{ textAlign: "justify" }}
                  mb="200px"
                >
                  Anju Ishiyama is an social activist. Born in 1989. As an
                  expert of Sharing economy in Japan, She is engaged in
                  spreading the sharing economy including regulatory reform and
                  policy making. Author of “SHARE LIFE - Dive into Sharing
                  Economy (Cross Media Publishing)". She is also a Founder of
                  Public Meets Innovation Japan which is a Think tank for the
                  Millennial generation leaders since 2018. Member of the World
                  Economic Forum Global Future Council Japan.
                </WrapTypo>
              </Grid>
              <Grid item xs={12} sm={5} style={{ width: "100%" }}>
                <Box>
                  <a href="https://amzn.asia/d/e8d4bco" alt="多拠点ライフ">
                    <Img
                      style={{ width: "100%", margin: "auto" }}
                      fluid={data.book.childImageSharp.fluid}
                    />
                  </a>
                </Box>
              </Grid>
            </GridResponsiveDirection>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

const GridResponsiveDirection = ({ children }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid
      container
      direction={isSmall ? "column-reverse" : "row"}
      justify="space-between"
    >
      {children}
    </Grid>
  );
};
